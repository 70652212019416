<template>
  <div id="app">
    <div class="container" style="margin-top: 30px">
      <div class="row mx-auto">
        <div class="col-md-8 col-sm-12 mx-auto">
          <form method="POST">
            <div class="form-group">
              <input
                id="orderId"
                type="text"
                class="form-control mr-2"
                v-model="orderId"
                placeholder="Enter your Order ID"
              />
            </div>
          </form>
        </div>
        <div class="col-md-8 col-sm-12 mx-auto" v-if="order && items">
          <Order
            :order="order"
            :items="items"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Order from "./components/order.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    Order,
  },
  data() {
    return {
      orderId: "",
      order: {},
      items: [],
    };
  },
  watch: {
    orderId: {
      handler() {
        this.getOrder();
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    getOrder() {
      axios
        .get(`https://accountsplanet.com/api/order/${this.orderId}`)
        .then((response) => {
          this.order = response.data;
          if (this.order) {
            this.$toast.success("Successfully found your order!");
          }
        });
    },
    getItems() {
      axios
        .get(`https://accountsplanet.com/api/items/${this.orderId}`)
        .then((response) => {
          this.items = response.data;
        });
    },
  },
};
</script>
<style>
@import url("./assets/app.css");
</style>