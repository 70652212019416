<template>
  <div>
    <section class="invoice-preview-wrapper">
      <div class="row invoice-preview">
        <!-- Invoice -->
        <div class="col-12">
          <div class="card invoice-preview-card">
            <div class="card-body invoice-padding pb-0">
              <!-- Header starts -->
              <div class="
                    d-flex
                    justify-content-between
                    flex-md-row flex-column
                    invoice-spacing
                    mt-0
                  ">
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo">AccsPremium</h3>
                  </div>

                  <p class="card-text mb-0">
                    {{ order.order_id }}
                  </p>

                  <div class="d-flex mt-2">
                    <img :src="
                      'https://accountsplanet.com' + order.product.image_path
                    " alt="product-img" class="rounded" width="100" />
                    <span class="pl-2 my-auto">{{ order.product.name }}</span>
                  </div>
                </div>
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">Warranty</h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">{{ order.warranty.name }}</p>
                  </div>
                </div>
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">Date</h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">{{ getHumanDate(order.created_at) }}</p>
                  </div>
                </div>
              </div>
              <!-- Header ends -->
            </div>

            <hr class="invoice-spacing" />
            <p class="card-text m-1" v-html="order.product.howtouse">
            </p>

            <!-- Invoice Description starts -->
            <div class="table-responsive" v-if="items">
              <table class="table">
                <thead>
                  <tr>
                    <th class="py-1">
                      Delivered Items
                      <button @click.prevent="submit"
                        v-if="basicWarranty(order.created_at) == true || order.warranty.id != 1"
                        class="ml-5 btn btn-primary btn-sm">
                        <span v-if="replacement == false">Select Replacement</span>
                        <span v-if="replacement == true">Request Replacement</span>
                      </button>
                      <button v-if="replacement == true" class="ml-5 btn btn-warning btn-sm" @click="cancel">
                        Cancel</button><br /><br />
                      <span class="stat-replacement" v-if="
                        this.order.replacement &&
                        this.order.replacement.status == 'Replacement' &&
                        this.order.product.qty < 1 &&
                        this.order.product.upgrade == 0 &&
                        this.order.refunded == 0
                      "><i>THIS PRODUCT IS CURRENTLY NO LONGER IN STOCK, AS SOON AS IT IS RESTOCKED YOU WILL RECEIVE YOUR REPLACEMENT</i></span>
                        <span class="stat-replacement" v-if="
                        this.order.replacement &&
                        this.order.replacement.status == 'Replacement' &&
                        this.order.product.qty >= 1 &&
                        this.order.refunded == 0
                      "><i>YOUR REPLACEMENT REQUEST IS BEING PROCESSED (BE
                          CAREFUL AFTER 20 REPLACEMENTS DECLINED DUE TO
                          FUNCTIONING ACCOUNTS YOUR POSSIBILITY OF REQUEST WILL
                          BE LIMITED)</i></span>
                      <span class="statRefunded" v-if="this.order.refunded == 1"><i>Your order is
                          refunded</i></span>
                      <span class="statReplaced" v-if="
                        this.order.replacement &&
                        this.order.replacement.status == 'Replaced' &&
                        this.order.refunded == 0
                      "><i v-if="this.order.product.upgrade == 0">Your Order is Replaced</i><i
                          v-if="this.order.product.upgrade != 0 && this.order.status == 2">Your order has been replaced
                          and your account will be upgraded shortly</i></span>
                      <span class="statDeclined" v-if="
                        this.order.replacement &&
                        this.order.replacement.status == 'Declined' &&
                        this.order.refunded == 0
                      "><i></i></span>
                      <span v-if="
                        !this.order.replacement &&
                        this.order.status == 2 &&
                        this.order.refunded == 0
                      "><i>Order awaiting upgrade</i></span>
                      <span v-if="
                        this.order.replacement &&
                        this.order.replacement.status != 'Replacement' &&
                        this.order.replacement.status != 'Declined' &&
                        this.order.status == 4 &&
                        this.order.refunded == 0
                      "><i>Order upgraded</i></span>
                      <span v-if="
                        this.order.replacement &&
                        this.order.replacement.status != 'Replacement' &&
                        this.order.replacement.status != 'Declined' &&
                        this.order.status == 3 &&
                        this.order.refunded == 0
                      "><i>Order upgrade failed</i></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="py-1">
                      <div class="d-flex">
                        <div class="custom-control custom-checkbox" v-if="replacement == true">
                          <input type="checkbox" class="custom-control-input" :id="`customCheck${item.id}`"
                            v-model="form.items" :value="item.id" />
                          <label v-if="getpwd(item.item) != null" class="custom-control-label"
                            :for="`customCheck${item.id}`">
                            <b>Login: </b>
                            <span style="cursor: pointer" @click="copyToClipBoard(getlogin(item.item))">
                              {{ getlogin(item.item) }}
                            </span>
                            <b> Password: </b>
                            <span style="cursor: pointer" @click="copyToClipBoard(getpwd(item.item))">
                              {{ getpwd(item.item) }}
                            </span>
                            <b v-if="getnumber(item.item) != null"> Phone Number: </b>
                            <span v-if="getnumber(item.item) != null" style="cursor: pointer;"
                              @click="copyToClipBoard(getnumber(item.item))">
                              {{ getnumber(item.item) }}
                            </span>
                            <b v-if="order.product.regionchoice == 1"> Region: </b>
                            <span v-if="order.product.regionchoice == 1">
                              {{ getcountry(item.item) }}
                            </span>
                            <b v-if="order.product.uniqid == 'f34254e4-1ae5-4db1-8c42-de117474ef5c'"> Backup Code: </b>
                            <span v-if="order.product.uniqid == 'f34254e4-1ae5-4db1-8c42-de117474ef5c'">
                              {{ getcountry(item.item) }}
                            </span>
                          </label>
                          <label v-if="getpwd(item.item) == null" class="custom-control-label"
                            :for="`customCheck${item.id}`">
                            <b>Code: </b>
                            <span style="cursor: pointer" @click="copyToClipBoard(getlogin(item.item))">
                              {{ getlogin(item.item) }}
                            </span>
                          </label>
                        </div>
                        <span v-if="
                          replacement == false && getpwd(item.item) != null
                        ">
                          <b>Login: </b>
                          <span style="cursor: pointer" @click="copyToClipBoard(getlogin(item.item))">
                            {{ getlogin(item.item) }}
                          </span>
                          <b> Password: </b>
                          <span style="cursor: pointer" @click="copyToClipBoard(getpwd(item.item))">
                            {{ getpwd(item.item) }}
                          </span>
                          <b v-if="getnumber(item.item) != null"> Phone Number: </b>
                          <span v-if="getnumber(item.item) != null" style="cursor: pointer;"
                            @click="copyToClipBoard(getnumber(item.item))">
                            {{ getnumber(item.item) }}
                          </span>
                          <b v-if="order.product.regionchoice == 1"> Region: </b>
                          <span v-if="order.product.regionchoice == 1">
                            {{ getcountry(item.item) }}
                          </span>
                          <b v-if="order.product.uniqid == 'f34254e4-1ae5-4db1-8c42-de117474ef5c'"> Backup Code: </b>
                          <span v-if="order.product.uniqid == 'f34254e4-1ae5-4db1-8c42-de117474ef5c'">
                            {{ getcountry(item.item) }}
                          </span>
                          <span style="font-size: 0.75rem">
                            (Click To Copy)</span>
                          <span class="pl-2 text-primary" v-if="item.replacement.length >= 1">
                            <i></i>
                          </span>
                        </span>
                        <span v-if="
                          replacement == false && getpwd(item.item) == null
                        ">
                          <b>Code: </b>
                          <span style="cursor: pointer" @click="copyToClipBoard(getlogin(item.item))">
                            {{ getlogin(item.item) }}
                          </span>
                          <span style="font-size: 0.75rem">
                            (Click To Copy)</span>
                          <span class="pl-2 text-primary" v-if="item.replacement.length >= 1">
                            <i></i>
                          </span>
                        </span>
                        <span v-if="replacement == true">
                          <span class="pl-2 text-primary" v-if="item.replacement.length >= 1">
                            <i>{{ item.replacement[0].status_item }}</i>
                          </span>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import $ from "jquery";
export default {
  props: ["order", "items"],
  data() {
    return {
      form: {
        product_id: this.order.product_id,
        order_id: this.order.id,
        items: [],
      },
      replacement: false,
      checkedInput: false,
    };
  },
  watch: {
    items: {
      handler() {
        this.form.product_id = this.order.product_id;
        this.form.order_id = this.order.id;
        this.form.order_id_long = this.order.order_id;
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      if (this.form.items.length >= 1 && this.replacement == true) {
        axios
          .post("https://accountsplanet.com/api/replacement", {
            product_id: this.order.product_id,
            order_id: this.order.id,
            items: this.form.items,
            order_id_long: this.order.order_id,
          })
          .then((response) => {
            this.$toast(response.data);
            if (!response.data.includes('expired')) {
              this.order.replacement.status = "Replacement";
            }
          });
      } else {
        this.replacement = true;
      }
    },
    getlogin(line) {
      var split = line.split(":");
      return split[0];
    },
    getpwd(line) {
      var split = line.split(":");
      return split[1];
    },
    getcountry(line) {
      var split = line.split(':');
      return split[2];
    },
    getnumber(line) {
      var split = line.split(":");
      return split[3];
    },
    copyToClipBoard(textToCopy) {
      const tmpTextField = document.createElement("textarea");
      tmpTextField.textContent = textToCopy;
      tmpTextField.setAttribute("style", "position:absolute; right:200%;");
      document.body.appendChild(tmpTextField);
      tmpTextField.select();
      tmpTextField.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      tmpTextField.remove();
      this.$toast("Successfuly copied!");
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    basicWarranty: function (date) {
      return moment(moment()).isBefore(moment(date, "YYYY-MM-DD").add(1, 'days'));
    },
    cancel() {
      if (this.replacement == true) {
        axios
          .get(`https://accountsplanet.com/api/items/${this.order_id}`)
          .then((response) => {
            this.form.items = response.data;
          });
      }
      this.replacement = false;
      $("input[type=checkbox]").each(function () {
        $(this).checked = false;
        $(this).prop("checked", false);
      });
    },
  },
};
</script>